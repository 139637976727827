import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Macros = () => (
  <Layout>
    <SEO title="Macros" />
    <div>
      <p>Here are some of the macros that I use.</p>
      <ul style={{listStyle: `none`,}}>
        <li>
          <p>Party targeting macros are great for quickly using an important ability on a party member. Dispel is so important that I also have the stopcasting part of the macro to interrupt anything else that I might be doing at the time that would prevent me from dispelling. I have one of these Macros for each of the two party members in 3v3 (party1 and party2).</p>
          <pre>
          #showtooltip
          <br></br>
          /stopcasting
          <br></br>
          /cast [@party1] Purify Spirit
          </pre>
        </li>
        <li>
          <p>Arena targeting macros are great for quickly acting on enemy players without changing your target. It makes it a lot easier to kick nearby players when they aren't your current target or use other important abilities. I have one of these for each arena frame in 3v3 (arena1, arena2, arena3). You can also do this with your focus target by changing 'arena1' to 'focus'.</p>
          <pre>
          #showtooltip
          <br></br>
          /stopcasting
          <br></br>
          /cast [@arena1] Wind Shear
          </pre>
        </li>
        <li>
          <p>This target of target macro will cast my spells on my target's target only if my target is friendly, else it will just cast on my target like regular. This is great as a healer for slowing or even dps'ing my team's kill target without dropping my friendly target. I also have it show the tooltip for a more important ability that actually has a cooldown instead of an ability that has none like frost shock.</p>
          <pre>
          #showtooltip Unleash Shield
          <br></br>
          /cast [harm][@targettarget] Frost Shock
          </pre>
        </li>
        <li>
          <p>This macro will use your trinket in the first trinket slot. The second trinket slot is 14. This macro is nice for swapping gear or getting new trinkets. You will always have it on your bar and won't ever forget to put your trinkets on your bar when you swap them.</p>
          <pre>
          #showtooltip
          <br></br>
          /use 13
          </pre>
        </li>
        <li>
          <p>@cursor macros are really important for skill shot like abilities like dropping totems. It saves an extra click and uses the ability faster than you would be able to otherwise. I have one for every skill shot totem and other abilites like Healing Rain. Some more examples for this type of macro would be: Freezing Trap, Door of Shadows, Holy Word: Sanctify, Power Word: Barrier, Ring of Peace, Efflorescence, Ursol's Vortex, and many more.</p>
          <pre>
          #showtooltip
          <br></br>
          /cast [@cursor] Spirit Link Totem
          </pre>
        </li>
        <li>
          <p>This macro will use an ability depending on what talent you have selected. This is nice for not having to switch anything on your bars when you change talents and no matter what spec you are it will show the relevant cooldown.</p>
          <pre>
          #showtooltip
          <br></br>
          /cast [talent:3/1] Typhoon; [talent:3/2] Swipe; [talent:3/3] Incapacitating Roar
          </pre>
        </li>
        <li>
          <p>Here is the same as above except with the added @cursor modifier I like to use for totems.</p>
          <pre>
          #showtooltip
          <br></br>
          /cast [talent:4/2, @cursor] Earthen Wall Totem; [talent:4/3, @cursor] Ancestral Protection Totem
          </pre>
        </li>
        <li>
          <p>This may seem silly but when your team is trying to kill a mage and keep you alive at the same time, you have to be careful not to let the mage spellsteal your blessing! If you really love something, you have to let it go :'(</p>
          <pre>
            /cancelaura Blessing of Protection
          </pre>
        </li>
      </ul>
    </div>

  </Layout>
)

export default Macros
